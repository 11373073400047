'use client';

// import Image from 'next/image';
// import Script from 'next/script';
import React from 'react';
import Link from 'next/link';

import '@STYLE/global.scss';
import '@STYLE/styles/common.css';

function FeatureSection() {
  return (
    <div className='feature1'>
      <div className='container-fluid p-0'>
        {/* Row */}
        <div className='row justify-content-center d-none'>
          <div className='col-md-7 text-center'>
            <h3 className='fw-600 font-eng display-4 m-b-40'>
              Why is it special
            </h3>
          </div>
        </div>
        <section
          id='introCompany'
          className='spacer'
          style={{ backgroundColor: '#ffffff' }}
        >
          <div className='container'>
            <div className='row d-flex justify-content-center'>
              <div className='col-lg-8 text-center'>
                <h3
                  className='fw-300 lh-10 font-eng h1 text-center'
                  data-aos='fade-down'
                  data-aos-duration='1200'
                  style={{
                    color: '#4E5254',
                    // textShadow: '2px 2px 2px #aaa',
                  }}
                >
                  <strong className='intStrongText'>I-ONE PAY</strong>
                  <br />
                  <div
                    style={{
                      fontWeight: 600,
                      lineHeight: '1.5em',
                      margin: '.3rem 0',
                      color: '#2349a7',
                    }}
                  >
                    지불결제서비스에 새로운 Value 를 제공하는
                  </div>
                  <span
                    style={{
                      fontSize: '1.5rem',
                      wordBreak: 'keep-all',
                      color: '#333',
                    }}
                  >
                    빠르게 변화하는 전자결제 서비스 요구에 온/오프라인
                    결제수단은 물론, 가맹점의 다양한 환경에 최적화된 통합
                    전자지불결제 서비스 입니다.
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </section>
        {/* Row */}
        <div className='col-10 text-center' style={{ margin: '0 auto' }}>
          <div className='bg-dark-light row no-gutters d-flex align-items-stretch'>
            {/* Column */}
            <div className='col-lg-4 col-md-6 wrap-feature1-box'>
              <div className='card' data-aos-duration='1200'>
                <div className='card-body text-center'>
                  <Link href='/page1'>
                    <div className='icon-space'>
                      <img src='/images/payai.jpg' alt='Payai' />
                    </div>
                    <h5
                      className='font-medium'
                      style={{ fontWeight: '600', color: '#555' }}
                    >
                      SMARTPAY AI
                    </h5>
                  </Link>
                  {/* <p className="m-t-20">. </p> */}
                </div>
              </div>
            </div>
            {/* Column */}
            <div className='col-lg-4 col-md-6 wrap-feature1-box m-t'>
              <div className='card' data-aos-duration='1200'>
                <div className='card-body text-center'>
                  <Link href='/page2'>
                    <div className='icon-space'>
                      <img src='/images/plan.jpg' alt='Plan' />
                    </div>
                    <h5
                      className='font-medium'
                      style={{ fontWeight: '600', color: '#555' }}
                    >
                      WISEPLAN
                    </h5>
                  </Link>
                  {/* <p className="m-t-20">Customizing.</p> */}
                </div>
              </div>
            </div>
            {/* Column */}
            <div className='col-lg-4 col-md-6 wrap-feature1-box m-t'>
              <div className='card last' data-aos-duration='1200'>
                <div className='card-body text-center'>
                  <Link href='/page3'>
                    <div className='icon-space'>
                      <img src='/images/paybiz.jpg' alt='Paybiz' />
                    </div>
                    <h5
                      className='font-medium'
                      style={{ fontWeight: '600', color: '#555' }}
                    >
                      SMARTPAY BIZ
                    </h5>
                  </Link>
                  {/* <p className="m-t-20"></p> */}
                </div>
              </div>
            </div>
            {/* Column */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureSection;

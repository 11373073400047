import React, { useEffect } from 'react';

const KakaoMap = ({ apiKey, containerId, latitude, longitude, level }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${apiKey}&autoload=false`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.kakao.maps.load(() => {
        const container = document.getElementById(containerId);
        const options = {
          center: new window.kakao.maps.LatLng(latitude, longitude),
          level: level,
        };
        const map = new window.kakao.maps.Map(container, options);

        // 지도에 마커 추가 (예시)
        const markerPosition = new window.kakao.maps.LatLng(
          latitude,
          longitude
        );
        const marker = new window.kakao.maps.Marker({
          position: markerPosition,
        });
        marker.setMap(map);
      });
    };

    return () => {
      document.head.removeChild(script);
    };
  }, [apiKey, containerId, latitude, longitude, level]);

  return <div id={containerId} style={{ width: '100%', height: '400px' }} />;
};

export default KakaoMap;

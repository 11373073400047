'use client';

import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
// import Script from 'next/script';

import '@STYLE/global.scss';
import '@STYLE/styles/common.css';

function ImgBlock() {
  return (
    <div className='bg-light'>
      <section className='imgBlock '>
        <div
          className='imgBlock-left col-lg-6'
          data-aos='fade-right'
          data-aos-duration='1200'
        >
          <div className='imgBg-holder '>
            <Image
              src='/images/half01.png'
              alt=''
              width={1200}
              height={800}
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        </div>
        <div className='container-fluid '>
          <div className='row height-main-half d-flex align-items-center'>
            <div className='col-lg-6' />
            <div
              className='col-lg-6 txt-right'
              data-aos='fade-down'
              data-aos-duration='1200'
            >
              <div className='inner-lg text-left'>
                <h5
                  className='font-medium'
                  style={{
                    display: 'inline-block',
                    padding: '.5rem',
                    // background: '#2349A7',
                    color: '#2349A7',
                    // textShadow: '2px 2px 4px #dcdcdc',
                    fontWeight: '600',
                    fontSize: '2rem',
                    marginBottom: '1rem',
                  }}
                >
                  안전하고 편리한 결제 환경
                </h5>
                <span className='intSpan'>
                  - 가맹점별로 결제수단별 거래내역, 정산내역의 집계 데이터를
                  확인할 수 있는 서비스를 제공하여 매출분석 및 운영관리의
                  어려움을 덜어드립니다.
                </span>
                <span className='intSpan'>
                  - 전자세금계산서 및 부가세신고 자료를 제공하여 정산업무를 쉽고
                  빠르게 수행할 수 있도록 지원해드립니다.
                </span>
                <Link
                  href='/page1'
                  className='btn btn-outline-inverse btn-rounded btn-md btn-arrow mt-5 '
                  data-aos='fade-up'
                  data-aos-duration='1200'
                  data-aos-delay='900'
                >
                  <span>
                    자세히 보기
                    <i className='ti-arrow-right' />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='imgBlock'>
        <div
          className='imgBlock-right col-lg-6'
          data-aos='fade-left'
          data-aos-duration='1200'
        >
          <div className='imgBg-holder'>
            <Image
              src='/images/half02.png'
              alt='SMARTPAY BIZ'
              width={828}
              height={552}
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        </div>
        <div className='container-fluid '>
          <div className='row height-main-half d-flex align-items-center'>
            <div
              className='col-lg-6 txt-left'
              data-aos='fade-down'
              data-aos-delay='300'
              data-aos-duration='1200'
            >
              <div className='inner-lg text-right'>
                <h5
                  className='font-medium'
                  style={{
                    display: 'inline-block',
                    padding: '.5rem',
                    // background: '#2349A7',
                    color: '#2349A7',
                    // textShadow: '2px 2px 4px #dcdcdc',
                    fontWeight: '600',
                    fontSize: '2rem',
                    marginBottom: '1rem',
                  }}
                >
                  가맹점 통합정산관리시스템
                </h5>
                <span className='intSpan'>
                  - 다양한 고객의 요구와 시스템환경에 최적화된 연동방식을
                  제공합니다.
                </span>
                <span className='intSpan'>
                  - 변화하는 기술에 맞춰 최신화된 알고리즘 및 암호화기술을
                  적용한 보안시스템을 사용합니다.
                </span>
                <Link
                  href='/page2'
                  className='btn btn-outline-inverse btn-rounded btn-md btn-arrow mt-5 '
                  data-aos='fade-up'
                  data-aos-duration='1200'
                  data-aos-delay='900'
                >
                  <span>
                    자세히 보기
                    <i className='ti-arrow-right' />
                  </span>
                </Link>
              </div>
            </div>
            <div className='col-lg-6' />
          </div>
        </div>
      </section>
    </div>
  );
}

export default ImgBlock;

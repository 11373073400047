'use client';

// import Script from 'next/script';
import '@STYLE/global.scss';

// import { useEffect } from 'react';
import Script from 'next/script';
// import Image from 'next/image';
// import Link from 'next/link';

// import Carousel from './_components/Carousel';
import SwiperComponent from './_components/SwiperComponent';
import FeatureSection from './_components/FeatureSection';
import ImgBlock from './_components/ImgBlock';
import FeatureSection9 from './_components/FeatureSection9';
import Gallery from './_components/Gallery';
import Contact from './_components/Contact';
import KakaoMap from './_components/kakaoMap';
import CountAtea from './_components/CountArea';

const customScriptContent = `
console.log('Custom script loaded');
`;

export default function Home() {
  return (
    <main>
      <main
        id='main-wrapper'
        className='mWrapper'
        // style={{ display: show ? 'block' : 'none' }}
      >
        <h2 className='blind'>본문시작</h2>
        <div id='page-wrapper' className='pWrapper'>
          {/* <Carousel /> */}
          <SwiperComponent />
          <FeatureSection />
          <ImgBlock />
          {/* <CountAtea /> */}
          <FeatureSection9 />
          <Gallery />
          <Contact />
          <KakaoMap
            apiKey='ac290f4776330c1300022503ff3f9b16'
            containerId='mapContainer'
            latitude={37.5665} // 서울의 위도
            longitude={126.978} // 서울의 경도
            level={3} // 확대 수준
          />
          {/* <div id='map' class='height-md w-100' /> */}
        </div>
      </main>
      <script src='https://unpkg.com/swiper/swiper-bundle.min.js' async />
      <Script
        id='custom-script'
        src='/js/jquery.min.js'
        strategy='afterInteractive'
      >
        {customScriptContent}
      </Script>

      <Script src='/js/jquery.waypoints.min.js' crossOrigin='anonymous' />
      <Script
        src='/js/jquery.counterup-custom.min.js'
        crossOrigin='anonymous'
      />
      <Script src='/js/jquery.magnific-popup.min.js' crossOrigin='anonymous' />
      <Script src='/js/popper.min.js' crossOrigin='anonymous' />
      <Script src='/js/aos.js' crossOrigin='anonymous' />
      <Script src='/js/prism.js' crossOrigin='anonymous' />
      <Script src='/js/custom.min.js' crossOrigin='anonymous' />
      <Script src='/js/imagesloaded.pkgd.min.js' crossOrigin='anonymous' />
      <Script src='/js/bootstrap.min.js' crossOrigin='anonymous' />
      <Script src='/js/isotope.pkgd.min.js' crossOrigin='anonymous' />
      <Script src='/js/portfolio.js' crossOrigin='anonymous' />

      <Script
        id='ui-script'
        dangerouslySetInnerHTML={{
          __html: `
            // 
            $.ajax({
              type: "get",
              url: "https://wisecare.co.kr/common/_AJAX/getCommonStatistic.php",
              data: {},
              dataType: "json",
              //async: false,
              success: function (res) {
                //console.log(res)

                if (res.is_success && res.cache) {
                  var cache = res.cache;

                  $('#stat-total-appli').data('initNum', cache.total_appli);
                  $('#stat-total-member').data('initNum', cache.total_member);
                  $('#stat-total-use').data('initNum', cache.total_use);

                  // 
                  $('#statistic-section').show();
                }
              },
              error: function (xhr, stat, error) {
                // .
              }
            });

          `,
        }}
      />
    </main>
  );
}

'use client';

import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
// import Script from 'next/script';

function Contact() {
  return (
    <section id='contactSection' className='contact-2'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-3 bg-light spacer'>
            <Link href='/contactUs' target='_self' className='thisMargin'>
              <div className='text-center px-5' data-aos-duration='1200'>
                <Image
                  src='/images/cnt.svg'
                  alt='Contact'
                  width={125}
                  height={75}
                  style={{ width: '100%', height: 'auto' }}
                />
                <h5 className='title h6 fw-600'>문의작성</h5>
                <p> 문의페이지로 </p>
              </div>
            </Link>
          </div>
          <div className='col-lg-3 bg-light-2 spacer'>
            <Link href='tel:1577-2596' className='thisMargin'>
              <div className='text-center px-5' data-aos-duration='1200'>
                <Image
                  src='/images/call.svg'
                  alt='AAAA'
                  width={125}
                  height={75}
                  style={{ width: '100%', height: 'auto' }}
                />
                <h5 className='title h6 fw-600'>상담원연결</h5>
                <p> 1577-2596 </p>
              </div>
            </Link>
          </div>
          <div className='col-lg-3 bg-light spacer'>
            <Link href='mailto:wisecare@wisecare.co.kr' className='thisMargin'>
              <div className='text-center px-5' data-aos-duration='1200'>
                <Image
                  src='/images/mail.svg'
                  alt='Send Mail'
                  width={125}
                  height={75}
                />
                <h5 className='title h6 fw-600'>메일보내기</h5>
                <p> wisecare@wisecare.co.kr </p>
              </div>
            </Link>
          </div>
          <div className='col-lg-3 bg-light spacer'>
            <Link href='mailto:wisecare@wisecare.co.kr' className='thisMargin'>
              <div className='text-center px-5' data-aos-duration='1200'>
                <Image
                  src='/images/talk.svg'
                  alt='talk'
                  width={125}
                  height={75}
                  style={{
                    width: '125px',
                    height: '75px',
                    display: 'block',
                    maxWidth: '100%',
                  }}
                />
                <h5 className='title h6 fw-600'>메신저문의</h5>
                <p> 09:00 ~ 17:30 </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;

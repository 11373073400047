'use client';

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import '@STYLE/swiper.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Autoplay } from 'swiper/modules';

import Image from 'next/image';

function MySwiperComponent() {
  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      slidesPerView={1}
      autoplay={{ delay: 5000 }}
      pagination={{ clickable: true }}
      className='mainSwiper'
    >
      <SwiperSlide className='swiperSlide'>
        <div className='item'>
          <header
            className='imgBg height-hero imgBg-bottom overflow-hidden'
            data-overlay='5'
          >
            <div className='imgBg-holder'>
              <Image
                src='/images/hero01.jpg'
                alt='mainVisual'
                width={1920}
                height={728.8}
                className='imageScale'
                id='imageScale'
                priority
              />
            </div>
            <div class='container'>
              <div class='row d-flex justify-content-center align-items-center height-hero'>
                <div
                  class='col-lg-12 col-10 text-center'
                  data-aos='fade-down'
                  data-aos-duration='1400'
                >
                  <h2 class='text-title fw-700 font-eng display-3 text-white mt-3 mb-3'>
                    <span className='visualTitle'>
                      아이원 페이먼츠, 미래의 결제를 만나다
                    </span>
                  </h2>
                  <p class=' text-white'>
                    <span className='visualSubTitle'>
                      혁신적이고 미래 지향적인 결제 솔루션{' '}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </header>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='item'>
          <header
            className='imgBg height-hero imgBg-bottom overflow-hidden'
            data-overlay='5'
          >
            <div className='imgBg-holder'>
              <Image
                src='/images/hero02.jpg'
                alt='mainVisual'
                width={1920}
                height={728.8}
                className='imageScale'
                id='imageScale'
                priority
              />
            </div>
            <div className='container'>
              <div className='row d-flex justify-content-center align-items-center height-hero'>
                <div
                  className='col-lg-12 col-10 text-center'
                  data-aos='fade-down'
                  data-aos-duration='1400'
                >
                  <h2 className='text-title fw-700 font-eng display-3 text-white mt-3 mb-3'>
                    <span className='visualTitle'>
                      안전하고 간편한 결제, 아이원과 함께
                    </span>
                  </h2>
                  <p className='text-white'>
                    <span className='visualSubTitle'>
                      신뢰 및 보안을 강화한 아이원페이
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </header>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='item'>
          <header
            className='imgBg height-hero imgBg-bottom overflow-hidden'
            data-overlay='5'
          >
            <div className='imgBg-holder'>
              <Image
                src='/images/hero03.jpg'
                alt='mainVisual'
                width={1920}
                height={728.8}
                className='imageScale'
                id='imageScale'
                priority
              />
            </div>
            <div className='container'>
              <div className='row d-flex justify-content-center align-items-center height-hero'>
                <div
                  className='col-lg-12 col-10 text-center'
                  data-aos='fade-down'
                  data-aos-duration='1400'
                >
                  <h2 className='text-title fw-700 font-eng display-3 text-white mt-3 mb-3'>
                    <span className='visualTitle'>
                      결제의 새로운 표준, 아이원 페이먼츠
                    </span>
                  </h2>
                  <p className='text-white'>
                    <span className='visualSubTitle'>
                      업계최고가 되겠음을 다짐합니다
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </header>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='item'>
          <header
            className='imgBg height-hero imgBg-bottom overflow-hidden'
            data-overlay='5'
          >
            <div className='imgBg-holder'>
              <Image
                src='/images/hero04.jpg'
                alt='mainVisual'
                width={1920}
                height={728.8}
                className='imageScale'
                id='imageScale'
                priority
              />
            </div>
            <div className='container'>
              <div className='row d-flex justify-content-center align-items-center height-hero'>
                <div
                  className='col-lg-12 col-10 text-center'
                  data-aos='fade-down'
                  data-aos-duration='1400'
                >
                  <h2 className='text-title fw-700 font-eng display-3 text-white mt-3 mb-3'>
                    <span className='visualTitle'>
                      당신의 결제를 스마트하게, 아이원 페이먼츠
                    </span>
                  </h2>
                  <p className='text-white'>
                    <span className='visualSubTitle'>
                      AI 프로그램으로 스마트하게 결제해보세요{' '}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </header>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default MySwiperComponent;

'use client';

// import Image from 'next/image';
// import Script from 'next/script';
import React from 'react';
import Link from 'next/link';

import '@STYLE/global.scss';
import '@STYLE/styles/common.css';

function FeatureSection9() {
  return (
    <section id='serviceCompany' className='spacer feature9'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 wrap-feature9-box b-r b-b'>
            <div className='card' data-aos-duration='1200'>
              <div className='card-body d-flex'>
                <div className='align-self-center'>
                  <h5 className='font-medium'>
                    <Link href='/page1' className='linking'>
                      <span className='intColorSpan'>신용카드결제</span>
                      란?
                    </Link>
                  </h5>
                  <p className='m-t-20 intText'>
                    고객이 온라인에서 상품 및 서비스를 신용카드로 결제하는
                    서비스입니다. 전달받은 신용정보는 안전하게 암호화되어 해당
                    신용카드사로 전달됩니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 wrap-feature9-box b-b'>
            <div className='card' data-aos-duration='1200'>
              <div className='card-body d-flex'>
                <div className='align-self-center'>
                  <h5 className='font-medium'>
                    <Link href='/page1' className='linking'>
                      <span className='intColorSpan'>수기결제</span>
                      란?
                    </Link>
                  </h5>
                  <p className='m-t-20 intText'>
                    비대면,비인증 결제방식 중 하나로 공인인증서와 카드단말기가
                    필요하지 않으며,직접 방문없이 고객이 카드결제를 할 수
                    있습니다.고객의 카드번호와 유효기간, CVC 등 최소한의
                    카드정보만을 입력받아 편리하게결제할 수 있는 결제방식입니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 wrap-feature9-box b-r'>
            <div className='card' data-aos-duration='1200'>
              <div className='card-body d-flex'>
                <div className='align-self-center'>
                  <h5 className='font-medium'>
                    <Link href='/page1' className='linking'>
                      <span className='intColorSpan'>SMS결제</span>
                      란?
                    </Link>
                  </h5>
                  <p className='m-t-20 intText'>
                    구매자가 결제요청 시 구매자의 명의로 된 휴대폰으로 결제
                    URL이 포함된 문자를 보내 결제를 진행하는 방식입니다.구매자는
                    결제 URL 내에서 카드정보를 직접 입력하여 쉽고 간편하게
                    결제를 진행할 수 있습니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 wrap-feature9-box'>
            <div className='card' data-aos-duration='1200'>
              <div className='card-body d-flex'>
                <div className='align-self-center'>
                  <h5 className='font-medium'>
                    <Link href='/page1' className='linking'>
                      <span className='intColorSpan'>가상계좌</span>
                      란?
                    </Link>
                  </h5>
                  <p className='m-t-20 intText'>
                    구매자가 선택한 은행사의 임시 입금전용계좌를
                    발급하고,구매자는 해당 가상계좌로 결제금액을 입금하는 방식의
                    서비스입니다.무통장입금과 방식이 유사하지만,가상계좌 결제
                    서비스는 전자결제사를 통해 진행되어 입금확인이 자동으로
                    이루어지는 것이 특징입니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureSection9;

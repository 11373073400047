'use client';

import Image from 'next/image';
// import Script from 'next/script';
import React from 'react';
// import Link from 'next/link';

import '@STYLE/global.scss';
import '@STYLE/styles/common.css';

function Gallery() {
  return (
    <section
      id='gallery'
      className='column-4 gutter-1px inner first bg-dark spacer'
    >
      <div className='container mb-5'>
        <div className='row'>
          <div className='col-lg-12 text-center'>
            <h1
              className='font-eng display-3 text-white fw-600'
              data-aos='fade-down'
              data-aos-duration='1200'
              data-aos-delay='100'
            >
              I - ONEPAY SERVICE
              <br className='hidden-lg-down' />
            </h1>
          </div>
        </div>
      </div>
      <div className='container-fluid' style={{ width: '70%' }}>
        <div className='row'>
          <div
            className='grid-gallery grid-style6'
            style={{ overflow: 'hidden' }}
          >
            <div className='tab-content'>
              <ul className='masonry-items grid lightbox-gallery'>
                <li className='one'>
                  <figure
                  // style={{ cursor: 'pointer' }}
                  // onClick={() =>
                  //   handleClick('/index/howto/page01.html')
                  // }
                  >
                    <div className='gallery-img'>
                      <Image
                        src='/images/04.jpg'
                        alt=''
                        width={588}
                        height={560}
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </div>
                    <figcaption>
                      <h4
                        className='h6 text-eng'
                        style={{
                          fontWeight: 'bold',
                          fontSize: '1.4rem',
                          textTransform: 'uppercase',
                        }}
                      >
                        smartpay ai
                      </h4>
                      <div className='grid-style1-border' />
                    </figcaption>
                  </figure>
                </li>
                <li className='three'>
                  <figure
                  // style={{ cursor: 'pointer' }}
                  // onClick={() =>
                  //   handleClick('/index/howto/page01.html')
                  // }
                  >
                    <div className='gallery-img'>
                      <Image
                        src='/images/02.jpg'
                        alt=''
                        width={588}
                        height={560}
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </div>
                    <figcaption>
                      <h4
                        className='h6 text-eng'
                        style={{
                          fontWeight: 'bold',
                          fontSize: '1.4rem',
                          textTransform: 'uppercase',
                        }}
                      >
                        smartpay biz
                      </h4>
                      <div className='grid-style1-border' />
                    </figcaption>
                  </figure>
                </li>
                <li className='three two'>
                  <figure
                  // style={{ cursor: 'pointer' }}
                  // onClick={() =>
                  //   handleClick('/index/howto/page01.html')
                  // }
                  >
                    <div className='gallery-img'>
                      <Image
                        src='/images/07.jpg'
                        alt=''
                        width={588}
                        height={560}
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </div>
                    <figcaption>
                      <h4
                        className='h6 text-eng'
                        style={{
                          fontWeight: 'bold',
                          fontSize: '1.4rem',
                          textTransform: 'uppercase',
                        }}
                      >
                        wise plan
                      </h4>
                      <div className='grid-style1-border' />
                    </figcaption>
                  </figure>
                </li>
                <li className='two'>
                  <figure
                  // style={{ cursor: 'pointer' }}
                  // onClick={() =>
                  //   handleClick('/index/howto/page01.html')
                  // }
                  >
                    <div className='gallery-img'>
                      <Image
                        src='/images/06.jpg'
                        alt=''
                        width={588}
                        height={560}
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </div>
                    <figcaption>
                      <h4
                        className='h6 text-eng'
                        style={{
                          fontWeight: 'bold',
                          fontSize: '1.4rem',
                          textTransform: 'uppercase',
                        }}
                      >
                        procedure
                      </h4>
                      <div className='grid-style1-border' />
                    </figcaption>
                  </figure>
                </li>
                <li className='two'>
                  <figure
                  // style={{ cursor: 'pointer' }}
                  // onClick={() =>
                  //   handleClick('/index/howto/page01.html')
                  // }
                  >
                    <div className='gallery-img'>
                      <Image
                        src='/images/03.jpg'
                        alt=''
                        width={588}
                        height={560}
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </div>
                    <figcaption>
                      <h4
                        className='h6 text-eng'
                        style={{
                          fontWeight: 'bold',
                          fontSize: '1.4rem',
                          textTransform: 'uppercase',
                        }}
                      >
                        history
                      </h4>
                      <div className='grid-style1-border' />
                    </figcaption>
                  </figure>
                </li>
                <li className='two'>
                  <figure
                  // style={{ cursor: 'pointer' }}
                  // onClick={() =>
                  //   handleClick('/index/howto/page01.html')
                  // }
                  >
                    <div className='gallery-img'>
                      <Image
                        src='/images/01.jpg'
                        alt=''
                        width={588}
                        height={560}
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </div>
                    <figcaption>
                      <h4
                        className='h6 text-eng'
                        style={{
                          fontWeight: 'bold',
                          fontSize: '1.4rem',
                          textTransform: 'uppercase',
                        }}
                      >
                        news
                      </h4>
                      <div className='grid-style1-border' />
                    </figcaption>
                  </figure>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Gallery;
